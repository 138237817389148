.input_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  .labelContainer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    label {
      margin: 0;
    }
  
    span {
      font-weight: 400;
      color: #707070;
    }
  }

  .input_wrapper {
    position: relative;

    .inputField {
      display: flex;
      padding: 3px 12px;
      width: 100%;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--gray-30-light-gray-rule-lines, #E4E4E4);
      background: var(--primary-2-pure-white, #FFF);
      min-width: 84px;
      color: #707070;
      font-weight: 400;
      font-size: 16px;
    
      @media screen and (max-width: 992px) {
        height: 36px;
        margin-bottom: 24px;
        width: 100%;
      }
      &.disabled{
        border: 1px solid var(--gray-30-light-gray-rule-lines, #e4e4e4);
        background: var(--gray-28-light-gray-small-bgs, #f4f4f4);
        pointer-events: none;
        cursor: not-allowed;
      }
      &.active {
        border: 2px solid var(--primary-1-amway-black, #2C2C2C);
      }
      &:not(.disabled)::placeholder { 
        color: #2C2C2C;
        font-size: 16px;
        font-weight: 500;
      }
      &.error {
        border: 2px solid #D91734;
      }
    }

    .error_icon_red {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      width: 20px;
      height: 20px;
      background-image: url(../../../styles/images/error_icon_red.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .error_message {
    color:  #D91734;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}