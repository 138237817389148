.form_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    border-bottom: 1px solid #E4E4E4;

    .fileUpload_container {
        display: flex;
        align-items: flex-end;
        margin-bottom: 6px;

        .fileUpload_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-weight: 700;

            .replaceCsv {
                text-decoration: underline;
                color: #38539A;
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                cursor: pointer;
                width: max-content;
            }

            .fileUpload {
                padding: 15px 0;
                flex: 1;
                border: 2px solid #2C2C2C;
                border-radius: 12px;
                cursor: pointer;
            }
        }

        .fileExtension {
            margin: 0;
            white-space: nowrap;
        }
    }

    .button_wrapper {
        display: flex;
        gap: 16px;
        padding: 8px 0;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column-reverse;
        }

        .btn {
            padding: 12px 32px;
            border-radius: 100px;
            border: 2px solid #2C2C2C;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;

            &.cancelBtn { 
                background-color: #FFF;
            }

            &.saveBtn { 
                background-color: #2C2C2C;
                color: #FFF;
            }

            &.disabled {
                pointer-events: none;
                background-color: #D9D9D9;
                color: #5E5E5E;
                border: none;
            }
        }
    }
}