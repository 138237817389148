@import '../../../styles/common/media_queries';

.drawer_wrapper {

    .header_wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;
        border-bottom: 1px solid #E4E4E4;

        @media screen and (max-width: 768px) {
            padding-top: 24px;
        }

        .header {
            font-size: 24px;
            font-weight: 700;
            margin: 0;
        }

        .close_icon {
            width: 12px;
            height: 12px;
            background-image: url(../../../styles/images/close.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
        }
    }

    .downloadCsv {
        display: inline-block;
        text-decoration: underline;
        color: #38539A;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        padding: 24px 0;
        cursor: pointer;
        width: max-content;
    }

    .downloadCsv_info {
        line-height: 24px;
        font-style: italic;
        font-size: 16px;
        align-self: stretch;
    }
}

.delete_btn_wrapper {
  border-radius: 24px;
  border: 2px solid #7f3e3e;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;
  .txt {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #7f3e3e;
    margin-right: 10px;
  }
  .delete_icon {
    width: 14px;
    height: 16px;
    background-image: url(../../../styles/images/delete_red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}
